declare module '@nuxt/schema' {
  interface PublicRuntimeConfig {
    recaptchaSiteKey: string
  }
}

// eslint-disable-next-line import/first
import type { PublicRuntimeConfig, RuntimeConfig } from '@nuxt/schema'

const rokkaHost = process.env.ROKKA_HOST || 'oris-local.rokka.io'
const recaptchaSiteKey = process.env.RECAPTCHA_SITE_KEY || ''

type PartialPublicRuntimeConfig = Omit<PublicRuntimeConfig, 'rokkaHost'> & {
  gtm: { id: string; debug: boolean; enabled: boolean }
}

const publicRuntimeConfig: PartialPublicRuntimeConfig = {
  // This requires a environment variable to be set in platform with the following name:
  // env:NUXT_PUBLIC_ROKKA_HOST
  // Example value: oris-testing.rokka.io
  rokkaHost,
  buildHash: process.env.PLATFORM_TREE_ID || Date.now().toString(),
  isDevelopment: process.env.NODE_ENV === 'development',
  isTesting: process.env.NODE_ENV === 'development',
  recaptchaSiteKey,
  gtm: {
    id: 'GTM-NQTW25',
    debug: false,
    enabled: false,
  },
}

export const runtimeConfig: {
  backendUrl: RuntimeConfig['backendUrl']
  public: PartialPublicRuntimeConfig
} = {
  backendUrl: process.env.BACKEND_URL ?? '',
  public: publicRuntimeConfig,
}
