<template>
  <Transition :name="'navbar-second-' + transitionDirection">
    <div
      v-show="isVisible"
      ref="el"
      class="absolute left-0 top-0 z-50 flex h-full w-full flex-col overflow-y-auto bg-white shadow-navbar-slide will-change-[opacity] lg:top-full lg:h-auto lg:overflow-y-visible lg:bg-transparent lg:pt-32 lg:shadow-none"
    >
      <AppTopNavbarSecondLevelMobileHeader
        :label="label"
        :class="{
          'translate-x-[-200px]': isMobileOrTablet && activeThirdIndex !== -1,
        }"
        @back="$emit('close')"
      />

      <div class="col-start-2 col-end-[-2] max-w-[1950px] pt-24">
        <div class="px-16 lg:relative lg:px-0">
          <div v-for="(link, linkIndex) in links" :key="linkIndex">
            <DrupalLink
              :to="link.href"
              class="group flex items-center justify-between pb-24 text-3xl uppercase text-grey-dark-02 transition duration-500 ease-swing hover:text-black lg:inline-flex lg:justify-start lg:text-6xl"
              :class="{
                'translate-x-[-200px]':
                  isMobileOrTablet && activeThirdIndex !== -1,
                'is-active': activeThirdIndex === linkIndex,
              }"
              @click.capture="onClick($event, linkIndex, !!link.links?.length)"
              @mouseenter="onClick($event, linkIndex, !!link.links?.length)"
            >
              <Text
                class="animated-underline relative inline-block before:!border-b-2"
              >
                {{ link.label }}
              </Text>
              <SpriteSymbol
                v-if="link.links?.length"
                name="chevron-right"
                class="ml-10 h-24 w-24 lg:ml-24 lg:h-28 lg:w-28"
              />
            </DrupalLink>

            <Transition name="navbar-second-away">
              <div
                v-if="link.links?.length"
                v-show="activeThirdIndex === linkIndex"
                class="absolute left-0 top-0 h-full w-full bg-white lg:left-2/3 lg:w-1/3"
              >
                <AppTopNavbarSecondLevelMobileHeader
                  :label="link.label"
                  class="mb-16"
                  @back="activeThirdIndex = -1"
                />
                <DrupalLink
                  v-for="(childLink, childIndex) in link.links"
                  :key="linkIndex + '_' + childIndex"
                  :to="childLink.href"
                  class="group block px-16 pb-24 text-lg text-grey-dark-02 hover:text-black"
                >
                  <Text
                    class="animated-underline relative inline-block before:!border-b-2"
                  >
                    {{ childLink.label }}
                  </Text>
                </DrupalLink>
              </div>
            </Transition>
          </div>
        </div>
      </div>
      <div class="h-[147px] shrink"></div>
      <Collections v-if="isProducts && becameVisibleOnce" class="shrink-0" />
    </div>
  </Transition>
</template>

<script lang="ts">
import Collections from './Collections/index.vue'

export type MenuLink = {
  label: string
  href: string
  links?: MenuLink[]
}
</script>

<script lang="ts" setup>
const props = defineProps<{
  index: number
  label: string
  isVisible: boolean
  transitionDirection: 'left' | 'right' | 'away'
  links: MenuLink[]
  isProducts?: boolean
}>()

// Used to defer loading additional menu content only when it becomes visible.
const becameVisibleOnce = ref(false)

watch(
  () => props.isVisible,
  () => {
    becameVisibleOnce.value = true
  },
)

const { isMobileOrTablet } = useViewport()

const el = ref<HTMLDivElement | null>(null)

const activeThirdIndex = ref(-1)

const onClick = (e: MouseEvent, index: number, hasChildren: boolean) => {
  if (!hasChildren) {
    return
  }

  e.preventDefault()
  e.stopPropagation()

  activeThirdIndex.value = index
}

const getIndex = () => props.index
const getHeight = (): number | undefined => el.value?.offsetHeight

defineExpose({ getIndex, getHeight })
defineEmits(['close'])
</script>

<style lang="postcss">
.navbar-second-left-enter-active,
.navbar-second-left-leave-active,
.navbar-second-right-enter-active,
.navbar-second-right-leave-active,
.navbar-second-away-enter-active,
.navbar-second-away-leave-active {
  @apply transition duration-500 ease-swing;
  @media (prefers-reduced-motion) {
    @apply !transition-none;
  }
}

.navbar-second-left-enter-from,
.navbar-second-left-leave-to {
  @apply -translate-x-56 opacity-0;
}

.navbar-second-right-enter-from,
.navbar-second-right-leave-to {
  @apply translate-x-56 opacity-0;
}

.navbar-second-away-enter-from,
.navbar-second-away-leave-to {
  @apply translate-x-full;
  @screen lg {
    @apply -translate-y-56 translate-x-0 opacity-0;
  }
}
</style>
