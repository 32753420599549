<template>
  <div
    ref="container"
    class="overflow-hidden bg-white transition-all duration-500 ease-swing lg:px-[70px]"
    :class="{
      'w-screen': isMobileOrTablet && menuIsOpenOnMobile,
    }"
    @mouseenter="mouseInside = true"
    @mouseleave="mouseInside = false"
  >
    <div
      class="relative z-50 mx-auto flex h-72 w-full max-w-screen-xxxl items-center justify-between bg-white px-[min(5cqw,32px)] lg:h-80 lg:px-0"
    >
      <Transition :name="isMobileOrTablet ? 'navbar-overlay' : undefined">
        <div
          v-show="menuIsOpenOnMobile || !isMobileOrTablet"
          ref="topLevelMenuList"
          class="absolute left-0 top-72 z-50 flex h-[calc(100dvh-72px)] w-full flex-col overflow-hidden pt-24 lg:static lg:h-auto lg:flex-row lg:justify-center lg:pt-0 xl:w-[calc((100%-100px)/2)] xl:flex-1 xl:justify-start"
        >
          <template v-for="(link, index) in links">
            <div class="lg:text-nowrap">
              <DrupalLink
                :to="link.href"
                class="group relative mb-16 flex items-center justify-between px-16 text-3xl uppercase text-grey-dark-01 transition duration-500 lg:mb-0 lg:p-0 lg:text-base"
                :class="{
                  'is-active':
                    activeIndex === index ||
                    (activeIndex === -1 && hoverIndex === index) ||
                    (activeIndex === -1 &&
                      hoverIndex === -1 &&
                      currentIndex === index),
                  'translate-x-[-200px]':
                    isMobileOrTablet && activeIndex !== -1,
                }"
                @click.capture="onClick($event, index, !!link.links?.length)"
                @mouseenter.stop.prevent="onMouseEnter(index)"
                @mouseleave="onMouseLeave"
              >
                <Text
                  class="animated-underline relative inline-block shrink overflow-hidden"
                  >{{ link.label }}</Text
                >

                <SpriteSymbol
                  v-if="link.links?.length"
                  name="chevron-right"
                  class="ml-10 h-24 w-24 shrink-0 lg:hidden"
                />
              </DrupalLink>

              <AppTopNavbarSecondLevel
                v-if="link.links?.length && !alwaysClosed"
                ref="menus"
                :key="'second_' + index"
                :is-visible="activeIndex === index"
                :index="index"
                :links="link.links"
                :label="link.label"
                :is-products="index === 0"
                :transition-direction="getTransitionDirection(index)"
                @close="setActiveIndex(-1)"
              />
            </div>
            <!-- spacer -->
            <div class="hidden w-[45px] shrink lg:block"></div>
          </template>
          <AppTopNavbarMobileNewsletter
            v-if="showNewsletter && isMobileOrTablet"
            class="mt-auto transition duration-500 ease-swing"
            :class="{
              'translate-x-[-200px]': isMobileOrTablet && activeIndex !== -1,
            }"
          />
        </div>
      </Transition>

      <a
        :href="homeLink"
        class="block flex-none lg:order-first lg:mr-[100px] xl:order-none xl:m-0"
        :title="$texts('goToHomepage', 'Go to homepage')"
        @click.prevent="router.push(homeLink)"
      >
        <SpriteSymbol
          name="logo-no-claim"
          class="oris-logo h-40 w-[100px] lg:hidden"
        />
        <SpriteSymbol
          name="logo"
          class="oris-logo hidden h-40 w-[100px] lg:block"
        />
      </a>

      <div
        class="flex flex-none justify-end gap-[min(6cqw,32px)] lg:w-[200px] lg:gap-40 xl:w-[calc((100%-100px)/2)]"
      >
        <AppTopMeta />
        <button
          class="block lg:hidden"
          :title="
            menuIsOpenOnMobile
              ? $texts('closeMenu', 'Close menu')
              : $texts('openMenu', 'Open menu')
          "
          @click="onBurgerClick"
        >
          <SpriteSymbol
            :name="menuIsOpenOnMobile ? 'close' : 'menu'"
            class="h-24 w-24"
          />
        </button>
      </div>
    </div>
  </div>

  <ClientOnly>
    <Teleport to="body">
      <Transition name="navbar-overlay">
        <div
          v-if="showOverlay"
          class="fixed left-0 top-0 z-navbar-overlay h-full w-full bg-black/30"
          @click.stop="setActiveIndex(-1)"
        />
      </Transition>
    </Teleport>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { useMouse } from '@vueuse/core'
import { type MainMenuLinkTreeFragment } from '#graphql-operations'
import AppTopNavbarSecondLevel, {
  type MenuLink,
} from '~/components/App/Top/Navbar/SecondLevel/index.vue'
import { falsy } from '~/helpers/type'

const router = useRouter()

const props = defineProps<{
  alwaysClosed?: boolean
}>()

const menuIsOpenOnDesktop = defineModel<boolean>('menuIsOpenOnDesktop')
const menuIsOpenOnMobile = defineModel<boolean>('menuIsOpenOnMobile')

type GraphqlMenuLink = MainMenuLinkTreeFragment & {
  subtree?: GraphqlMenuLink[]
}

const homeLink = computed(() => router.resolve({ name: 'home' }).href)

const mapLink = (v: GraphqlMenuLink | undefined): MenuLink | undefined => {
  if (v && v.link.url?.path && v.link.label) {
    return {
      label: v.link.label,
      href: v.link.url.path,
      links: v.subtree?.map(mapLink).filter(falsy),
    }
  }
}

const { data } = await useInitData()

const links = computed(
  () => data.value.menuLinks.map(mapLink).filter(falsy) || [],
)

const route = useRoute()

let raf: null | number = null

const { isMobileOrTablet } = useViewport()

const showNewsletter = ref(false)
const prevActiveIndex = ref(-1)
const activeIndex = ref(-1)
const hoverIndex = ref(-1) // needed for the mobile menu
const container = ref<HTMLElement>()
const menus = ref<Array<InstanceType<typeof AppTopNavbarSecondLevel>>>([])
const topLevelMenuList = ref<HTMLElement>()
const mouseInside = ref(false)

// We need this delay for one frame since the mouse value is lacking behind, especially when moving it fast.
const deferMenuCollapse = ref(true)

// Put all sub and subsub links of a toplevel menu entry into one array. Will look in the end something similar to:
// [
//   ['/de-US/product/watch', '/de-US/product/strap', '/de-US/products/theres-love-item-everyone', '/de-US/products'],
//   ['/de-US/worldoforis'],
//   ['/de-US/storefinder', '/de-US/stores-service/customer-service', '/de-US/stores-service']
// ]
// We need this to calculate the currentIndex
const flattenedLinks = computed(() => {
  const flattenedLinks: string[][] = []

  const flatten = (acc: string[], link: MenuLink): string[] => {
    return [...acc, link.href, ...(link.links?.reduce(flatten, []) ?? [])]
  }
  for (const link of links.value) {
    const linkArray = link.links?.reduce(flatten, []) ?? []
    linkArray.push(link.href)
    flattenedLinks.push(linkArray)
  }

  return flattenedLinks
})

const currentIndex = computed(() => {
  // find the currentIndex within the flattenedLinks
  return flattenedLinks.value.findIndex((links) =>
    links.some((link) => route.path.includes(link)),
  )
})

watch(
  () => route.path,
  () => {
    menuIsOpenOnMobile.value = false
    activeIndex.value = -1
  },
)

// disable scrolling on the main html if the mobile menu is open.
watch(menuIsOpenOnMobile, () => {
  const body = document.querySelector('html')
  if (body) {
    if (menuIsOpenOnMobile.value) {
      body.style.overflow = 'hidden'
    } else {
      // only using overflow-y does not work on iOS Safari (iPhone 13 mini)
      body.style.removeProperty('overflow')
    }
  }
})

const getTransitionDirection = (index: number) => {
  if (
    isMobileOrTablet.value ||
    activeIndex.value === -1 ||
    prevActiveIndex.value === -1
  ) {
    // The active menu will move away (menu is being closed).
    return 'away'
  }

  // Switch to a higher index.
  if (activeIndex.value > prevActiveIndex.value) {
    if (index === prevActiveIndex.value) {
      return 'left' // Previous menu moves left.
    }
    return 'right' // Current menu moves right.
  }

  // Transitioning to a lower index
  if (index === prevActiveIndex.value) {
    return 'right' // Previous menu moves right.
  }

  return 'left' // Current menu moves left.
}

const onBurgerClick = () => {
  showNewsletter.value = true

  if (menuIsOpenOnMobile.value) {
    activeIndex.value = -1
  }

  menuIsOpenOnMobile.value = !menuIsOpenOnMobile.value
}

const onClick = (e: MouseEvent, index: number, hasChildren: boolean) => {
  if (!hasChildren) {
    return
  }

  e.preventDefault()
  e.stopPropagation()
  setActiveIndex(index)
}

const onMouseEnter = (index: number) => {
  if (!isMobileOrTablet.value) {
    setActiveIndex(index)
  } else {
    hoverIndex.value = index
  }
}

const onMouseLeave = () => {
  hoverIndex.value = -1
}

const setActiveIndex = (index: number) => {
  if (activeIndex.value === index) {
    return
  }
  prevActiveIndex.value = activeIndex.value
  activeIndex.value = index
}

const navHeight = computed(() => (isMobileOrTablet.value ? 72 : 80))
const { x, y } = useMouse({ touch: false })

const loop = () => {
  if (!container.value || !topLevelMenuList.value) {
    menuIsOpenOnDesktop.value = false
    return
  }

  if (isMobileOrTablet.value) {
    if (menuIsOpenOnMobile.value) {
      container.value.style.height = `${window.innerHeight}px`
    } else {
      container.value.style.removeProperty('height')
    }

    menuIsOpenOnDesktop.value = false
    return
  }

  if (activeIndex.value === -1 || props.alwaysClosed) {
    container.value.style.removeProperty('height')
    menuIsOpenOnDesktop.value = false
    return
  }

  const menuEntryHeight =
    menus.value.find((v) => v.getIndex() === activeIndex.value)?.getHeight() ||
    0

  if (menuEntryHeight > 0) {
    menuIsOpenOnDesktop.value = true

    const contentHeight = Math.max(
      menuEntryHeight + navHeight.value,
      // avoid that the menu dropdown gets too small
      window.innerHeight * 0.6,
    )
    container.value.style.height = `${contentHeight}px`
  } else {
    menuIsOpenOnDesktop.value = false
    container.value.style.removeProperty('height')
  }

  const yInsideNavbar =
    y.value - window.scrollY <
      container.value.getBoundingClientRect().top + navHeight.value &&
    y.value - window.scrollY > container.value.getBoundingClientRect().top

  if (
    (!mouseInside.value &&
      // allow going out of the window to the left or right and keep the menu open.
      x.value + 20 < container.value.getBoundingClientRect().right &&
      x.value - 20 > container.value.getBoundingClientRect().left) ||
    (yInsideNavbar &&
      // we need +/- 1 here since mouse enter and x.value deviate 1 pixel if done slowly.
      (x.value + 1 < topLevelMenuList.value.getBoundingClientRect().left ||
        x.value - 1 > topLevelMenuList.value.getBoundingClientRect().right)) // pointer moved too far left or right in the top row
  ) {
    if (deferMenuCollapse.value) {
      deferMenuCollapse.value = false
    } else {
      deferMenuCollapse.value = true
      setActiveIndex(-1)
    }
  }

  raf = window.requestAnimationFrame(loop)
}

const showOverlay = computed(() => {
  if (props.alwaysClosed || activeIndex.value === -1) {
    return false
  }

  if (isMobileOrTablet.value) {
    return menuIsOpenOnMobile.value
  }

  return (links.value[activeIndex.value].links?.length ?? 0) > 0
})

watch(activeIndex, loop)
watch(menuIsOpenOnMobile, loop)
watch(isMobileOrTablet, () => {
  setActiveIndex(-1)
  menuIsOpenOnMobile.value = false
})

onMounted(() => {
  loop()
})

onBeforeUnmount(() => {
  if (raf) {
    cancelAnimationFrame(raf)
  }
})
</script>

<style lang="postcss">
.animated-underline {
  &:before {
    content: '';
    @apply absolute bottom-0 h-1 w-full origin-left scale-x-0 border-b border-b-transparent transition duration-300 ease-swing;
  }
}

.group.is-active,
.group:hover {
  .animated-underline:before {
    @apply scale-x-100 border-b-current;
  }
}

.navbar-overlay-enter-active,
.navbar-overlay-leave-active {
  @apply transition duration-300 ease-swing;
  @media (prefers-reduced-motion) {
    @apply !transition-none;
  }
}

.navbar-overlay-enter-from,
.navbar-overlay-leave-to {
  @apply opacity-0;
}
</style>
