import { default as index6VIsPLY9QAMeta } from "/app/pages/[prefix]/[...slug]/index.vue?macro=true";
import { default as indexVqvd4CIKXXMeta } from "/app/layers/cart/pages/[prefix]/cart/index.vue?macro=true";
import { default as indexYXAC16ekWVMeta } from "/app/pages/[prefix]/index.vue?macro=true";
import { default as indexyWS05ccSCmMeta } from "/app/pages/[prefix]/loveitem-finder/index.vue?macro=true";
import { default as indexLqY1yKc8ftMeta } from "/app/layers/world-of-oris/pages/[prefix]/magazine-article/index.vue?macro=true";
import { default as indexJcjB1hcSo7Meta } from "/app/pages/[prefix]/product/[...fallback]/index.vue?macro=true";
import { default as _91sku_939Mnvbz2UMzMeta } from "/app/pages/[prefix]/product/[type]/[name]/[sku].vue?macro=true";
import { default as _91sku_93vWe0BujPBhMeta } from "/app/pages/[prefix]/product/strap/[name]/[sku].vue?macro=true";
import { default as _91sku_93IReSj16XByMeta } from "/app/pages/[prefix]/product/watch/[collection]/[watchName]/[sku].vue?macro=true";
import { default as indexv86JMrmc9XMeta } from "/app/pages/[prefix]/product/watch/[collection]/index.vue?macro=true";
import { default as indexuSHsnVm7rCMeta } from "/app/pages/[prefix]/storefinder/index.vue?macro=true";
import { default as indexFxqVLfjQITMeta } from "/app/pages/[prefix]/strap-finder/index.vue?macro=true";
import { default as indexKfG8wXq5DMMeta } from "/app/pages/[prefix]/styleguide/buttons/index.vue?macro=true";
import { default as indexlEOZkbFlVRMeta } from "/app/pages/[prefix]/styleguide/colors/index.vue?macro=true";
import { default as CheckboxG7IGjieNi3Meta } from "/app/pages/[prefix]/styleguide/inputs/Checkbox.vue?macro=true";
import { default as Dropdown9WEAg1d529Meta } from "/app/pages/[prefix]/styleguide/inputs/Dropdown.vue?macro=true";
import { default as indexrFDamEdMdxMeta } from "/app/pages/[prefix]/styleguide/inputs/index.vue?macro=true";
import { default as RadiobMGFWZjYf0Meta } from "/app/pages/[prefix]/styleguide/inputs/Radio.vue?macro=true";
import { default as RadioGroupofRWo6Pw3sMeta } from "/app/pages/[prefix]/styleguide/inputs/RadioGroup.vue?macro=true";
import { default as TextInputlVhcP8UC0rMeta } from "/app/pages/[prefix]/styleguide/inputs/TextInput.vue?macro=true";
import { default as indexjBOXlxpl7wMeta } from "/app/pages/[prefix]/styleguide/link/index.vue?macro=true";
import { default as gallery_45gridwyIS2uUO5FMeta } from "/app/pages/[prefix]/styleguide/paragraph/gallery-grid.vue?macro=true";
import { default as text_45imager5iCSoWyLGMeta } from "/app/pages/[prefix]/styleguide/paragraph/text-image.vue?macro=true";
import { default as textdu1tVJD9SCMeta } from "/app/pages/[prefix]/styleguide/paragraph/text.vue?macro=true";
import { default as videozLXWbayIQ7Meta } from "/app/pages/[prefix]/styleguide/paragraph/video.vue?macro=true";
import { default as indexquQZZuoFwHMeta } from "/app/pages/[prefix]/styleguide/slider/index.vue?macro=true";
import { default as indexePVO12GBoiMeta } from "/app/pages/[prefix]/styleguide/variations/index.vue?macro=true";
import { default as indexJekZXUJhrPMeta } from "/app/pages/[prefix]/watch-finder/index.vue?macro=true";
import { default as indextgVPeD9Gb9Meta } from "/app/layers/world-of-oris/pages/[prefix]/worldoforis/[category]/index.vue?macro=true";
import { default as indexaMi1GQm58TMeta } from "/app/layers/world-of-oris/pages/[prefix]/worldoforis/index.vue?macro=true";
import { default as worldoforisMGc3uR3z0MMeta } from "/app/layers/world-of-oris/pages/[prefix]/worldoforis.vue?macro=true";
export default [
  {
    name: index6VIsPLY9QAMeta?.name ?? "prefix-slug",
    path: index6VIsPLY9QAMeta?.path ?? "/:prefix()/:slug(.*)*",
    meta: index6VIsPLY9QAMeta || {},
    alias: index6VIsPLY9QAMeta?.alias || [],
    redirect: index6VIsPLY9QAMeta?.redirect,
    component: () => import("/app/pages/[prefix]/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVqvd4CIKXXMeta?.name ?? "prefix-cart",
    path: indexVqvd4CIKXXMeta?.path ?? "/:prefix()/cart",
    meta: indexVqvd4CIKXXMeta || {},
    alias: indexVqvd4CIKXXMeta?.alias || [],
    redirect: indexVqvd4CIKXXMeta?.redirect,
    component: () => import("/app/layers/cart/pages/[prefix]/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexYXAC16ekWVMeta?.name ?? "prefix",
    path: indexYXAC16ekWVMeta?.path ?? "/:prefix()",
    meta: indexYXAC16ekWVMeta || {},
    alias: indexYXAC16ekWVMeta?.alias || [],
    redirect: indexYXAC16ekWVMeta?.redirect,
    component: () => import("/app/pages/[prefix]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyWS05ccSCmMeta?.name ?? "prefix-loveitem-finder",
    path: indexyWS05ccSCmMeta?.path ?? "/:prefix()/loveitem-finder",
    meta: indexyWS05ccSCmMeta || {},
    alias: indexyWS05ccSCmMeta?.alias || [],
    redirect: indexyWS05ccSCmMeta?.redirect,
    component: () => import("/app/pages/[prefix]/loveitem-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexLqY1yKc8ftMeta?.name ?? "prefix-magazine-article",
    path: indexLqY1yKc8ftMeta?.path ?? "/:prefix()/magazine-article",
    meta: indexLqY1yKc8ftMeta || {},
    alias: indexLqY1yKc8ftMeta?.alias || [],
    redirect: indexLqY1yKc8ftMeta?.redirect,
    component: () => import("/app/layers/world-of-oris/pages/[prefix]/magazine-article/index.vue").then(m => m.default || m)
  },
  {
    name: indexJcjB1hcSo7Meta?.name ?? "prefix-product-fallback",
    path: indexJcjB1hcSo7Meta?.path ?? "/:prefix()/product/:fallback(.*)*",
    meta: indexJcjB1hcSo7Meta || {},
    alias: indexJcjB1hcSo7Meta?.alias || [],
    redirect: indexJcjB1hcSo7Meta?.redirect,
    component: () => import("/app/pages/[prefix]/product/[...fallback]/index.vue").then(m => m.default || m)
  },
  {
    name: _91sku_939Mnvbz2UMzMeta?.name ?? "prefix-product-type-name-sku",
    path: _91sku_939Mnvbz2UMzMeta?.path ?? "/:prefix()/product/:type()/:name()/:sku()",
    meta: _91sku_939Mnvbz2UMzMeta || {},
    alias: _91sku_939Mnvbz2UMzMeta?.alias || [],
    redirect: _91sku_939Mnvbz2UMzMeta?.redirect,
    component: () => import("/app/pages/[prefix]/product/[type]/[name]/[sku].vue").then(m => m.default || m)
  },
  {
    name: _91sku_93vWe0BujPBhMeta?.name ?? "prefix-product-strap-name-sku",
    path: _91sku_93vWe0BujPBhMeta?.path ?? "/:prefix()/product/strap/:name()/:sku()",
    meta: _91sku_93vWe0BujPBhMeta || {},
    alias: _91sku_93vWe0BujPBhMeta?.alias || [],
    redirect: _91sku_93vWe0BujPBhMeta?.redirect,
    component: () => import("/app/pages/[prefix]/product/strap/[name]/[sku].vue").then(m => m.default || m)
  },
  {
    name: _91sku_93IReSj16XByMeta?.name ?? "prefix-product-watch-collection-watchName-sku",
    path: _91sku_93IReSj16XByMeta?.path ?? "/:prefix()/product/watch/:collection()/:watchName()/:sku()",
    meta: _91sku_93IReSj16XByMeta || {},
    alias: _91sku_93IReSj16XByMeta?.alias || [],
    redirect: _91sku_93IReSj16XByMeta?.redirect,
    component: () => import("/app/pages/[prefix]/product/watch/[collection]/[watchName]/[sku].vue").then(m => m.default || m)
  },
  {
    name: indexv86JMrmc9XMeta?.name ?? "prefix-product-watch-collection",
    path: indexv86JMrmc9XMeta?.path ?? "/:prefix()/product/watch/:collection()",
    meta: indexv86JMrmc9XMeta || {},
    alias: indexv86JMrmc9XMeta?.alias || [],
    redirect: indexv86JMrmc9XMeta?.redirect,
    component: () => import("/app/pages/[prefix]/product/watch/[collection]/index.vue").then(m => m.default || m)
  },
  {
    name: indexuSHsnVm7rCMeta?.name ?? "prefix-storefinder",
    path: indexuSHsnVm7rCMeta?.path ?? "/:prefix()/storefinder",
    meta: indexuSHsnVm7rCMeta || {},
    alias: indexuSHsnVm7rCMeta?.alias || [],
    redirect: indexuSHsnVm7rCMeta?.redirect,
    component: () => import("/app/pages/[prefix]/storefinder/index.vue").then(m => m.default || m)
  },
  {
    name: indexFxqVLfjQITMeta?.name ?? "prefix-strap-finder",
    path: indexFxqVLfjQITMeta?.path ?? "/:prefix()/strap-finder",
    meta: indexFxqVLfjQITMeta || {},
    alias: indexFxqVLfjQITMeta?.alias || [],
    redirect: indexFxqVLfjQITMeta?.redirect,
    component: () => import("/app/pages/[prefix]/strap-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexKfG8wXq5DMMeta?.name ?? "prefix-styleguide-buttons",
    path: indexKfG8wXq5DMMeta?.path ?? "/:prefix()/styleguide/buttons",
    meta: indexKfG8wXq5DMMeta || {},
    alias: indexKfG8wXq5DMMeta?.alias || [],
    redirect: indexKfG8wXq5DMMeta?.redirect,
    component: () => import("/app/pages/[prefix]/styleguide/buttons/index.vue").then(m => m.default || m)
  },
  {
    name: indexlEOZkbFlVRMeta?.name ?? "prefix-styleguide-colors",
    path: indexlEOZkbFlVRMeta?.path ?? "/:prefix()/styleguide/colors",
    meta: indexlEOZkbFlVRMeta || {},
    alias: indexlEOZkbFlVRMeta?.alias || [],
    redirect: indexlEOZkbFlVRMeta?.redirect,
    component: () => import("/app/pages/[prefix]/styleguide/colors/index.vue").then(m => m.default || m)
  },
  {
    name: CheckboxG7IGjieNi3Meta?.name ?? "prefix-styleguide-inputs-Checkbox",
    path: CheckboxG7IGjieNi3Meta?.path ?? "/:prefix()/styleguide/inputs/Checkbox",
    meta: CheckboxG7IGjieNi3Meta || {},
    alias: CheckboxG7IGjieNi3Meta?.alias || [],
    redirect: CheckboxG7IGjieNi3Meta?.redirect,
    component: () => import("/app/pages/[prefix]/styleguide/inputs/Checkbox.vue").then(m => m.default || m)
  },
  {
    name: Dropdown9WEAg1d529Meta?.name ?? "prefix-styleguide-inputs-Dropdown",
    path: Dropdown9WEAg1d529Meta?.path ?? "/:prefix()/styleguide/inputs/Dropdown",
    meta: Dropdown9WEAg1d529Meta || {},
    alias: Dropdown9WEAg1d529Meta?.alias || [],
    redirect: Dropdown9WEAg1d529Meta?.redirect,
    component: () => import("/app/pages/[prefix]/styleguide/inputs/Dropdown.vue").then(m => m.default || m)
  },
  {
    name: indexrFDamEdMdxMeta?.name ?? "prefix-styleguide-inputs",
    path: indexrFDamEdMdxMeta?.path ?? "/:prefix()/styleguide/inputs",
    meta: indexrFDamEdMdxMeta || {},
    alias: indexrFDamEdMdxMeta?.alias || [],
    redirect: indexrFDamEdMdxMeta?.redirect,
    component: () => import("/app/pages/[prefix]/styleguide/inputs/index.vue").then(m => m.default || m)
  },
  {
    name: RadiobMGFWZjYf0Meta?.name ?? "prefix-styleguide-inputs-Radio",
    path: RadiobMGFWZjYf0Meta?.path ?? "/:prefix()/styleguide/inputs/Radio",
    meta: RadiobMGFWZjYf0Meta || {},
    alias: RadiobMGFWZjYf0Meta?.alias || [],
    redirect: RadiobMGFWZjYf0Meta?.redirect,
    component: () => import("/app/pages/[prefix]/styleguide/inputs/Radio.vue").then(m => m.default || m)
  },
  {
    name: RadioGroupofRWo6Pw3sMeta?.name ?? "prefix-styleguide-inputs-RadioGroup",
    path: RadioGroupofRWo6Pw3sMeta?.path ?? "/:prefix()/styleguide/inputs/RadioGroup",
    meta: RadioGroupofRWo6Pw3sMeta || {},
    alias: RadioGroupofRWo6Pw3sMeta?.alias || [],
    redirect: RadioGroupofRWo6Pw3sMeta?.redirect,
    component: () => import("/app/pages/[prefix]/styleguide/inputs/RadioGroup.vue").then(m => m.default || m)
  },
  {
    name: TextInputlVhcP8UC0rMeta?.name ?? "prefix-styleguide-inputs-TextInput",
    path: TextInputlVhcP8UC0rMeta?.path ?? "/:prefix()/styleguide/inputs/TextInput",
    meta: TextInputlVhcP8UC0rMeta || {},
    alias: TextInputlVhcP8UC0rMeta?.alias || [],
    redirect: TextInputlVhcP8UC0rMeta?.redirect,
    component: () => import("/app/pages/[prefix]/styleguide/inputs/TextInput.vue").then(m => m.default || m)
  },
  {
    name: indexjBOXlxpl7wMeta?.name ?? "prefix-styleguide-link",
    path: indexjBOXlxpl7wMeta?.path ?? "/:prefix()/styleguide/link",
    meta: indexjBOXlxpl7wMeta || {},
    alias: indexjBOXlxpl7wMeta?.alias || [],
    redirect: indexjBOXlxpl7wMeta?.redirect,
    component: () => import("/app/pages/[prefix]/styleguide/link/index.vue").then(m => m.default || m)
  },
  {
    name: gallery_45gridwyIS2uUO5FMeta?.name ?? "prefix-styleguide-paragraph-gallery-grid",
    path: gallery_45gridwyIS2uUO5FMeta?.path ?? "/:prefix()/styleguide/paragraph/gallery-grid",
    meta: gallery_45gridwyIS2uUO5FMeta || {},
    alias: gallery_45gridwyIS2uUO5FMeta?.alias || [],
    redirect: gallery_45gridwyIS2uUO5FMeta?.redirect,
    component: () => import("/app/pages/[prefix]/styleguide/paragraph/gallery-grid.vue").then(m => m.default || m)
  },
  {
    name: text_45imager5iCSoWyLGMeta?.name ?? "prefix-styleguide-paragraph-text-image",
    path: text_45imager5iCSoWyLGMeta?.path ?? "/:prefix()/styleguide/paragraph/text-image",
    meta: text_45imager5iCSoWyLGMeta || {},
    alias: text_45imager5iCSoWyLGMeta?.alias || [],
    redirect: text_45imager5iCSoWyLGMeta?.redirect,
    component: () => import("/app/pages/[prefix]/styleguide/paragraph/text-image.vue").then(m => m.default || m)
  },
  {
    name: textdu1tVJD9SCMeta?.name ?? "prefix-styleguide-paragraph-text",
    path: textdu1tVJD9SCMeta?.path ?? "/:prefix()/styleguide/paragraph/text",
    meta: textdu1tVJD9SCMeta || {},
    alias: textdu1tVJD9SCMeta?.alias || [],
    redirect: textdu1tVJD9SCMeta?.redirect,
    component: () => import("/app/pages/[prefix]/styleguide/paragraph/text.vue").then(m => m.default || m)
  },
  {
    name: videozLXWbayIQ7Meta?.name ?? "prefix-styleguide-paragraph-video",
    path: videozLXWbayIQ7Meta?.path ?? "/:prefix()/styleguide/paragraph/video",
    meta: videozLXWbayIQ7Meta || {},
    alias: videozLXWbayIQ7Meta?.alias || [],
    redirect: videozLXWbayIQ7Meta?.redirect,
    component: () => import("/app/pages/[prefix]/styleguide/paragraph/video.vue").then(m => m.default || m)
  },
  {
    name: indexquQZZuoFwHMeta?.name ?? "prefix-styleguide-slider",
    path: indexquQZZuoFwHMeta?.path ?? "/:prefix()/styleguide/slider",
    meta: indexquQZZuoFwHMeta || {},
    alias: indexquQZZuoFwHMeta?.alias || [],
    redirect: indexquQZZuoFwHMeta?.redirect,
    component: () => import("/app/pages/[prefix]/styleguide/slider/index.vue").then(m => m.default || m)
  },
  {
    name: indexePVO12GBoiMeta?.name ?? "prefix-styleguide-variations",
    path: indexePVO12GBoiMeta?.path ?? "/:prefix()/styleguide/variations",
    meta: indexePVO12GBoiMeta || {},
    alias: indexePVO12GBoiMeta?.alias || [],
    redirect: indexePVO12GBoiMeta?.redirect,
    component: () => import("/app/pages/[prefix]/styleguide/variations/index.vue").then(m => m.default || m)
  },
  {
    name: indexJekZXUJhrPMeta?.name ?? "prefix-watch-finder",
    path: indexJekZXUJhrPMeta?.path ?? "/:prefix()/watch-finder",
    meta: indexJekZXUJhrPMeta || {},
    alias: indexJekZXUJhrPMeta?.alias || [],
    redirect: indexJekZXUJhrPMeta?.redirect,
    component: () => import("/app/pages/[prefix]/watch-finder/index.vue").then(m => m.default || m)
  },
  {
    name: worldoforisMGc3uR3z0MMeta?.name ?? undefined,
    path: worldoforisMGc3uR3z0MMeta?.path ?? "/:prefix()/worldoforis",
    meta: worldoforisMGc3uR3z0MMeta || {},
    alias: worldoforisMGc3uR3z0MMeta?.alias || [],
    redirect: worldoforisMGc3uR3z0MMeta?.redirect,
    component: () => import("/app/layers/world-of-oris/pages/[prefix]/worldoforis.vue").then(m => m.default || m),
    children: [
  {
    name: indextgVPeD9Gb9Meta?.name ?? "prefix-worldoforis-category",
    path: indextgVPeD9Gb9Meta?.path ?? ":category()",
    meta: indextgVPeD9Gb9Meta || {},
    alias: indextgVPeD9Gb9Meta?.alias || [],
    redirect: indextgVPeD9Gb9Meta?.redirect,
    component: () => import("/app/layers/world-of-oris/pages/[prefix]/worldoforis/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexaMi1GQm58TMeta?.name ?? "prefix-worldoforis",
    path: indexaMi1GQm58TMeta?.path ?? "",
    meta: indexaMi1GQm58TMeta || {},
    alias: indexaMi1GQm58TMeta?.alias || [],
    redirect: indexaMi1GQm58TMeta?.redirect,
    component: () => import("/app/layers/world-of-oris/pages/[prefix]/worldoforis/index.vue").then(m => m.default || m)
  }
]
  }
]