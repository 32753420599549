import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import blokkliEditable_kzWzvLoIBF from "/app/node_modules/blokkli-beta/dist/runtime/plugins/blokkliEditable.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import clickaway_ZmIhydr7La from "/app/plugins/clickaway.ts";
import analyticsDefaultConsent_client_dNkvPS29yU from "/app/plugins/analyticsDefaultConsent.client.ts";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import clientCache_SyeCpR1f31 from "/app/plugins/clientCache.ts";
import provideState_6DkFrUCTO4 from "/app/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.mjs";
import graphqlMiddleware_2xI7SMabjg from "/app/plugins/graphqlMiddleware.ts";
import staticNodes_n2RLvQu6RX from "/app/plugins/staticNodes.ts";
import texts_VtwR4mVBrN from "/app/node_modules/@bazinga/vuepal/dist/runtime/plugins/texts.mjs";
import cart_client_d2trXyAyXb from "/app/plugins/cart.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  blokkliEditable_kzWzvLoIBF,
  chunk_reload_client_UciE0i6zes,
  clickaway_ZmIhydr7La,
  analyticsDefaultConsent_client_dNkvPS29yU,
  plugin_tbFNToZNim,
  clientCache_SyeCpR1f31,
  provideState_6DkFrUCTO4,
  graphqlMiddleware_2xI7SMabjg,
  staticNodes_n2RLvQu6RX,
  texts_VtwR4mVBrN,
  cart_client_d2trXyAyXb
]