<template>
  <footer class="mx-16 lg:mx-56">
    <div
      class="md:pb-46 mx-auto max-w-screen-xxxl bg-grey-light-03 px-16 py-40 lg:px-64 lg:pt-64"
    >
      <div class="mx-auto">
        <div
          class="relative mb-40 grid items-start border-b border-grey-light-01 pb-24 text-sm md:mb-56 md:flex md:pb-32"
        >
          <NewsletterSubscription />
        </div>

        <div class="page-footer-grid mt-30 text-base md:mb-40">
          <div class="grid-area-t">
            <a
              :href="homeLink"
              class="mb-40 self-start"
              :title="$texts('goToHomepage', 'Go to homepage')"
              @click.prevent="router.push(homeLink)"
            >
              <SpriteSymbol name="logo" class="block h-32 w-80" />
            </a>
            <p
              v-if="footer?.text"
              class="mb-30 mt-24 text-sm font-light text-grey-dark-01"
            >
              {{ footer?.text }}
            </p>
          </div>

          <PageFooterMainLinks
            class="grid-area-m"
            :links="data.footerMainMenu.links"
          />

          <div class="grid-area-s mt-32 lg:mt-0">
            <SocialMediaLinks
              class="mb-24 md:mb-32"
              :links="footer?.socialMediaLinks"
            />
            <p
              v-if="footer?.tagline"
              class="text-sm font-light text-black mobile-only:mb-32"
            >
              {{ footer?.tagline }}
            </p>
          </div>
        </div>

        <div
          class="gap-20 relative mt-30 grid items-start pt-30 text-sm lg:flex lg:items-center lg:gap-30"
        >
          <PageFooterMetaLinks :links="data.footerMetaMenu.links" />

          <PageFooterLocaleSwitcher
            class="mr-auto text-base/[24px] font-light text-grey-dark-02 mobile-only:mt-48"
            :current-country-label="currentCountryLabel"
          />
        </div>
      </div>
    </div>
    <div
      class="my-24 flex items-center justify-center gap-[13px] text-sm/[24px] font-bold text-black md:mb-40 md:mt-32"
    >
      <div>© ORIS</div>
      <SpriteSymbol name="logo-shield" class="h-[34px] w-30" />
      <div>{{ currentYear }}</div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
const { data } = await useInitData()

const router = useRouter()

const homeLink = computed(() => router.resolve({ name: 'home' }).href)

const currentYear = computed(() => new Date().getFullYear().toString())
const currentCountryLabel = computed(() => data.value.currentCountryLabel)

const footer = computed(() => data.value.footerConfig)
</script>

<style lang="postcss">
.page-footer-grid {
  @screen lg {
    @apply grid gap-24;
    grid-template-columns: 12rem 1fr;
    grid-template-rows: 1fr 1fr auto;

    grid-template-areas:
      'T  M'
      'T  M'
      'S  M';
  }

  @screen xl {
    grid-template-columns: 20rem 1fr;
  }
}
</style>
