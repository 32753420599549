<template>
  <div ref="root">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
const root = ref<HTMLElement | null>(null)

if (process.browser) {
  let scalingInProgress = false

  function downscaleFont() {
    scalingInProgress = true

    // request animation frame here to do the calculation only when everything is layed out. E.g. flex box content.
    requestAnimationFrame(() => {
      const element = root.value
      const parentElement = element?.parentElement

      if (!element || !parentElement) {
        return
      }

      // reset font size from previous scaling
      element.style.removeProperty('font-size')
      element.style.removeProperty('line-height')

      // We need to set overflow (to anything) otherwise scrollHeight is not > clientHeight if a flexbox expands over the top of the element (instead of the usual over the bottom).
      element.style.setProperty('overflow', 'hidden')

      const initialFontSize = parseInt(
        getComputedStyle(element).getPropertyValue('font-size'),
      )
      let currentFontSize = initialFontSize

      const widthToCompareTo = () =>
        // we need to ceil here since scrollWidth is always an int and we otherwise downscale unnecessarily
        Math.ceil(
          Math.min(
            element.getBoundingClientRect().width,
            parentElement.getBoundingClientRect().width,
          ),
        )

      const heightToCompareTo = () =>
        // we need to ceil here since scrollHeight is always an int and we otherwise downscale unnecessarily
        Math.ceil(
          Math.min(
            element.getBoundingClientRect().height,
            parentElement.getBoundingClientRect().height,
          ),
        )

      const initialScrollWidth = element.scrollWidth
      let lastScrollWidth = 0
      let currentScrollWidth = initialScrollWidth

      const initialScrollHeight = element.scrollHeight
      let lastScrollHeight = 0
      let currentScrollHeight = initialScrollHeight

      while (
        (currentScrollWidth > widthToCompareTo() ||
          currentScrollHeight > heightToCompareTo()) &&
        (lastScrollWidth !== currentScrollWidth ||
          lastScrollHeight !== currentScrollHeight)
      ) {
        lastScrollWidth = currentScrollWidth
        lastScrollHeight = currentScrollHeight
        currentFontSize -= 1
        element.style.fontSize = currentFontSize + 'px'
        currentScrollWidth = element.scrollWidth
        currentScrollHeight = element.scrollHeight
      }

      if (
        lastScrollWidth === currentScrollWidth &&
        lastScrollHeight === currentScrollHeight
      ) {
        // last decrease did not help
        currentFontSize += 1
        element.style.fontSize = currentFontSize + 'px'
      }

      if (
        currentScrollWidth === initialScrollWidth &&
        currentScrollHeight === initialScrollHeight
      ) {
        // font size changing did not help, so remove it to not unnecessary downscale text.
        element.style.removeProperty('font-size')
      } else {
        // also scale line height to avoid awkward large line height compared to font size.
        const lineHeight = parseInt(
          getComputedStyle(element).getPropertyValue('line-height'),
        )

        // 1.33 is min line height scale for smallest text. Check tailwind.config.ts
        element.style.lineHeight =
          currentFontSize * Math.max(lineHeight / initialFontSize, 1.33) + 'px'
      }

      element.style.removeProperty('overflow')

      // only set scalingInProgress to false after the next animation frame so the resize observer does not trigger / skips calling downscaleFont()
      // requestAnimationFrame(() => {
      scalingInProgress = false
    })
  }

  onMounted(() => {
    const resizeObserver = new ResizeObserver(() => {
      // font scaling changes the size so do not trigger then.
      if (!scalingInProgress) {
        downscaleFont()
      }
    })

    // start listening to changes
    if (root.value !== null) {
      root.value.innerHTML = root.value.innerHTML
        .replaceAll('Sixty-Five', 'Sixty&#x2011;Five') // dont do .replaceAll('-', '&#x2011;') since this messes up the HTML if the Text element has further children.
        .replaceAll('Pointer Date', 'Pointer&nbsp;Date')

      resizeObserver.observe(root.value)
    }
  })
}
</script>
