<template>
  <a
    :href="watchfinder"
    class="block"
    :title="$texts('watchFinder', 'Watch Finder')"
    @click.prevent="router.push(watchfinder)"
  >
    <SpriteSymbol name="watch" class="h-24 w-24" />
  </a>
  <a
    :href="cart"
    class="relative block"
    :title="$texts('cart', 'Cart')"
    @click.prevent="router.push(cart)"
  >
    <SpriteSymbol name="shopping-bag" class="h-24 w-24" />
    <ClientOnly>
      <div
        v-if="count && route.name !== 'cart'"
        class="absolute -right-12 -top-12 flex h-[22px] w-[22px] items-center justify-center rounded-full bg-blue-dark-01 py-4 text-xs text-white"
      >
        {{ count }}
      </div>
    </ClientOnly>
  </a>
  <a
    :href="`/${currentLanguage}-${currentCountry}/myoris`"
    target="_blank"
    class="block"
    title="MyOris"
  >
    <SpriteSymbol name="user" class="h-24 w-24" />
  </a>
</template>

<script setup lang="ts">
const route = useRoute()
const router = useRouter()
const { count } = useCart()
const currentLanguage = useCurrentLanguage()
const currentCountry = useCurrentCountry()

const watchfinder = computed(
  () => router.resolve({ name: 'watch-finder' }).href,
)
const cart = computed(() => router.resolve({ name: 'cart' }).href)
</script>
