import { useWindowSize } from '@vueuse/core'

type Viewport =
  /**
   * Mobile.
   */
  | 'sm'

  /**
   * Tablet.
   */
  | 'md'

  /**
   * Desktop.
   */
  | 'lg'

  /**
   * Widescreen.
   */
  | 'xl'

  /**
   * FullHD.
   */
  | 'xxl'

  /**
   * 4K.
   */
  | 'xxxl'

type UseViewport = {
  /**
   * Whether the current viewport is mobile.
   */
  isMobile: ComputedRef<boolean>

  /**
   * Whether the current viewport is mobile or tablet.
   */
  isMobileOrTablet: ComputedRef<boolean>

  /**
   * The current viewport ID.
   */
  viewport: ComputedRef<Viewport>

  /**
   * Reactively check if the viewport is one of the given viewport IDs.
   *
   * @example
   * const shouldRenderSlider = computed(() => isViewport('xxl', 'xl', 'lg'))
   */
  isViewport: (...viewports: Viewport[]) => boolean
}

export default function (): UseViewport {
  const windowSize = useWindowSize()

  const viewport = computed<Viewport>(() => {
    if (windowSize.width.value >= 2558) {
      return 'xxxl'
    } else if (windowSize.width.value >= 1440) {
      return 'xxl'
    } else if (windowSize.width.value >= 1200) {
      return 'xl'
    } else if (windowSize.width.value >= 1024) {
      return 'lg'
    } else if (windowSize.width.value >= 834) {
      return 'md'
    }

    return 'sm'
  })

  const isMobile = computed(() => viewport.value === 'sm')

  const isMobileOrTablet = computed(
    () => viewport.value === 'sm' || viewport.value === 'md',
  )

  const isViewport = (...viewports: Viewport[]) =>
    viewports.includes(viewport.value)

  return { isMobile, isMobileOrTablet, viewport, isViewport }
}
