import { defineNuxtPlugin } from 'nuxt/app'
import type { FetchResponse, FetchOptions } from 'ofetch'
import type {
  DrupalMessage,
  DrupalMessageType,
} from '~/composables/useDrupalMessages'

interface GraphqlResponseWithMessage {
  data: {
    messengerMessages?: Array<{
      type: DrupalMessageType
      message: string
      escaped: string
      safe: string
    }>
  }
}

/**
 * Try to extract the messages from a GraphQL query or mutation.
 */
function extractMessages(
  response: FetchResponse<GraphqlResponseWithMessage>,
): DrupalMessage[] {
  if (
    response._data &&
    typeof response._data === 'object' &&
    response._data.data.messengerMessages &&
    Array.isArray(response._data.data.messengerMessages)
  ) {
    return response._data.data.messengerMessages.map((v) => {
      return {
        type: v.type,
        message: v.safe,
      }
    })
  }

  return []
}

/**
 * This is only called when performing a query or mutation from within the nuxt
 * app (e.g. not via custom server routes).
 */
export default defineNuxtPlugin(() => {
  const state = useGraphqlState()
  const { messages } = useDrupalMessages()
  const language = useCurrentLanguage()
  const country = useCurrentCountry()
  const config = useRuntimeConfig()

  const fetchOptions: FetchOptions = {
    onResponse(result) {
      // Extract drupal messages from every GraphQL response.
      extractMessages(result.response).forEach((v) => {
        const exists = messages.value.find((m) => m.message === v.message)
        if (!exists) {
          messages.value.push(v)
        }
      })
    },
    onRequest({ options }) {
      try {
        if (!options.params) {
          options.params = {}
        }

        options.params.__language_context = language.value
        options.params.__country_context = country.value
        options.params.__hash = config.public.buildHash

        if (!options.headers) {
          options.headers = {}
        }
        options.headers = useRequestHeaders()
      } catch (e) {}
    },
  }

  state.fetchOptions = fetchOptions
})
